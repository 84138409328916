<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <div class="table_head_right">
        <transition name="el-fade-in"> </transition>
      </div>
      <!-- </div> -->
      <!-- 表格渲染 -->
      <div class="table">
        <!-- 设置表格高度  height="500" -->
        <div style="margin-left: -65%; margin-bottom: 10px">物流>物流跟踪</div>
        <div class="bck">
          <div
            style="
              width: 95%;
              font-weight: bold;
              margin-bottom: 20px;
              display: flex;
              margin-left: 2.5%;
              justify-content: space-between;
            "
          >
            <div>物流跟踪</div>
            <el-form
              ref="form"
              style="display: flex;"
              label-width="100px"
            >
              <el-form-item label="订单编号：">
                <el-input
                  placeholder="请输入订单编号"
                  v-model="orderSn"
                ></el-input>
              </el-form-item>
              <el-button
                type="danger"
                size="medium"
                @click="selData()"
                style="margin-left: 5%;width: 100px;height: 42px;"
              >
                查询
              </el-button>
            </el-form>
          </div>

          <el-table
            :data="labelList"
            border
            style="width: 95%; margin-left: 2.5%; text-align: center"
          >
            <el-table-column prop="orderid" label="编号" align="center">
            </el-table-column>
            <el-table-column prop="order_sn" label="订单编号" align="center">
            </el-table-column>

            <el-table-column prop="addressData" label="收件人信息" align="center">
              <template slot-scope="scope">
               <div>{{scope.row.addressData[0]}}</div>
               <div>{{scope.row.addressData[1]}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="express_sn" label="快递单号" align="center">
            </el-table-column>
            <el-table-column
              prop="operation"
              label="操作"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <span
                  style="margin-left: 10px; cursor: pointer"
                  @click="expreQuery(scope.row.order_sn)"
                >
                  查看物流
                </span>
                <span
                  style="margin-left: 10px; cursor: pointer"
                  @click="copy(scope.row.order_sn)"
                >
                  复制单号</span
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[10]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="titlePage"
            >
            </el-pagination>
            <div></div>
          </div>
        </div>
      </div>
      <!-- 分页 -->

      <!-- <el-popover placement="end"  width="500" height="500px" trigger="click">
        <el-row :gutter="12" style="border:1px solid red">
          <el-col :span="8">
            <el-card shadow="always"> 总是显示 </el-card>
          </el-col>
        </el-row>

        <el-button slot="reference">click 激活</el-button>
      </el-popover> -->
    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";

export default {
  data() {
    return {
      top1: "12-1",
      top2: ["12"],
      flag: 1,
      // admin_type: "",
      xstoreid: "",
      labelList: [],
      selects: "",
      admin_type: "",
      selectp: "",
      activeTitle: "",
      //选择活动状态
      active_status: "",
      merchantShow: false,
      show: false,
      changeShow: false,
      /*  businessList: [],
				business_id: '', */
      businessList: [{}],
      business_id: "",
      ml_id: "",
      pstoreid: "",
      storeList: [{}],
      storeid: "",
      s_name: "",
      //添加活动
      l_name: "",
      l_count: "",
      l_money: "",
      titlePage: 0,
      //物流信息
      userName: "",
      orderSn: "",
      sizeForm: {
        labelList: [],
        name: "",
        merchant: "",
        date1: "",
        date2: "",
        endDate1: "",
        endDate2: "",
        delivery: "",
        type: [],
        resource: "",
        desc: "",
      },
      //修改活动
      sizeForm2: {
        active_id: "",
        active_title: "",
      },
      cur_page: 0, //设置一个默认值
      //表格渲染数据
      tableData: [],

      //总页数
      currentPage4: 1,
      currentpage: 1,
      pageSize: 10,
      // tatal: ,
      value2: true,
    };
  },
  methods: {
    selData() {
        console.log(this.userName);
        console.log(this.orderSn);

        let login_type = this.$storage.getLocal("type");
        this.admin_type = login_type;
        let token = this.$storage.getLocal("token");
        this.$request
          .selOrderExpressList({
            token,
            storeid: "",
            login_type: login_type,
            page: this.currentpage,
            limit: this.pageSize,
            order_sn: this.orderSn,
          })
          .then((r) => {
            if (r.code == 0) {
              console.log(r);
              this.labelList = r.data.data;
              this.titlePage = r.data.count;
            }
          });
    },
    copy(e) {
      let copyText = e;
      var input = document.createElement("input"); // 直接构建input
      input.value = copyText; // 设置内容
      console.log(input.value);

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
    },
    expreQuery(e) {
      this.$router.push({ path: "/expressInfo", query: { orderSn: e } });
    },

    //刷新页面按钮
    refresh() {
      location.reload();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      // console.log(this.pageSize);
      this.pageSize = parseInt(`${val}`);
      // console.log("456", this.currentpage);
      console.log("361", this.pageSize);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentpage = `${val}`;
      // console.log("789", this.currentpage);
      // this.admin_type = this.$storage.getLocal("admin_type");
      let login_type = this.$storage.getLocal("type");
      let token = this.$storage.getLocal("token");
      this.$request
        .memberLabelList({
          token,
          storeid: "",
          login_type: login_type,
          page: `${val}`,
          limit: this.pageSize,
        })
        .then((r) => {
          // console.log(123)
          if (r.code == 0) {
            console.log(r);
            this.labelList = r.data.data;
          }
        });
    },
    delActive(val) {
      let token = this.$storage.getLocal("token");
      this.$request
        .memberLabelDel({
          token,
          ml_id: val,
        })
        .then((r) => {
          if (r.code == 0) {
            location.reload();
          }
        });
    },
    onSubmit() {
      console.log("222", this.storeid);
      console.log("333", this.ml_id);
      let login_type = this.$storage.getLocal("type");
      let token = this.$storage.getLocal("token");
      if (this.ml_id == "") {
        this.$request
          .memberLabelAdd({
            token,
            storeid: this.storeid,
            // ml_id:this.ml_id,
            login_type: login_type,
            name: this.l_name,
            count: this.l_count,
            money: this.l_money,
          })
          .then((r) => {
            // console.log(123)
            if (r.code == 0) {
              this.show = false;
              location.reload();
            }
          });
      } else {
        this.$request
          .memberLabelUp({
            token,
            storeid: this.storeid,
            ml_id: this.ml_id,
            login_type: login_type,
            name: this.l_name,
            count: this.l_count,
            money: this.l_money,
          })
          .then((r) => {
            // console.log(123)
            if (r.code == 0) {
              this.show = false;
              location.reload();
            }
          });
      }
    },

    shopType(value) {
      console.log(value);
      this.storeid = value;
      // let token = this.$storage.getLocal("token");
      // this.$request
      //   .getStoreDataList({
      //     token,
      //     pstoreid: value,
      //     login_type: this.$storage.getLocal("type"),
      //   })
      //   .then((r) => {
      //     console.log(123);
      //     if (r.code == 0) {
      //       this.storeList = r.data;
      //       console.log(r);
      //     }
      //   });
    },
    storeidType(value) {
      // this.storeid = value;
    },
    //编辑活动的提交按钮
    onChange() {
      if (this.sizeForm2.title == "") {
        alert("活动名称不能为空，时间不能为空，上下架不能为空");
        console.log("不能为空");
      } else {
      }
    },
    edit(name, count, money, ml_id, storeid, store_name) {
      // console.log(val)

      this.show = true;
      this.l_name = name;
      this.l_count = count;
      this.l_money = money;
      console.log(ml_id);
      console.log(storeid);
      this.ml_id = ml_id;
      this.storeid = storeid;
      let token = this.$storage.getLocal("token");
      this.$request
        .getUpStoreData({
          token,
          storeid: storeid,
        })
        .then((r) => {
          // console.log(123)
          if (r.code == 0) {
            console.log(r);
            this.pstoreid = r.data.pstoreid;
            this.$request
              .getStoreDataList({
                token,
                pstoreid: r.data.pstoreid,
                login_type: this.$storage.getLocal("type"),
              })
              .then((r) => {
                console.log(123);
                if (r.code == 0) {
                  this.storeList = r.data;

                  console.log(r);
                }
              });
          }
        });
      console.log(this.storeid);
    },
  },

  created() {
    // this.admin_type = this.$storage.getLocal("admin_type");
    let login_type = this.$storage.getLocal("type");
    this.admin_type = login_type;
    let token = this.$storage.getLocal("token");
    this.$request
      .selOrderExpressList({
        token,
        storeid: "",
        login_type: login_type,
        page: this.currentpage,
        limit: this.pageSize,
        order_sn: "",
      })
      .then((r) => {
        if (r.code == 0) {
          console.log(r);
          this.labelList = r.data.data;
          this.titlePage = r.data.count;
        }
      });

    if (login_type == "1") {
      this.$request
        .getMerchantData({
          token,
        })
        .then((r) => {
          if (r.code == 0) {
            this.businessList = r.data;
            console.log(r);
          }
        });
    } else if (login_type == "2") {
      this.$request
        .getStoreDataList({
          token,
          pstoreid: "",
          login_type: this.$storage.getLocal("type"),
        })
        .then((r) => {
          if (r.code == 0) {
            this.selectp = r.data;
            console.log(r);
          }
        });
    }
  },
  watch: {},
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}

.left,
.right {
  display: inline;
}

.left {
  float: left;
  margin-left: 210px;
}

.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}

.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}

.screen {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 90px;
}

.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}

.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}

.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}

.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}

.transition-box {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 500px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}

.act_tit {
  width: 800px;
  height: 50px;
  background-color: rgb(236, 128, 141);
  margin-top: -40px;
  margin-left: -20px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}

.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}

.block {
  margin-top: 30px;
}

.listcont {
  display: flex;
}

.shuai {
  display: flex;
  /* border:1px solid black */
}

.selname {
  width: 200px;
  border: 1px solid black;
}

.sel {
  /* margin-left: 10px; */
  margin-top: 5px;
}
.table {
  background-color: rgb(245, 247, 249);
  padding-top: 10px;
  padding-bottom: 25px;
  min-height: 90vh;
}

.bck {
  width: 85%;
  background-color: white;
  /* margin-top: 20px; */
  /* border: 1px solid red; */
  margin-left: 14%;
  /* margin-top: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
  /* line-height: 20px; */
}
* {
  /* background-color: red; */
  /* border: 1px solid deeppink; */
}
</style>
